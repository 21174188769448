<template>
  <main>
    <BannerComponent :title="banner[$props.lang].title"
                     :subtitle="banner[$props.lang].subtitle"
                     :image="require('@/assets/software_audit.svg')"
                     background="#F0E68C"
                     color="#222222"
                     :lang="$props.lang"
                     note=""
                     @open-modal-contact="$emit('open-modal-contact')"
    />
    <div class="container is-widescreen ">
      <div class="columns p-4 is-multiline">
        <div class="column is-one-quarter" v-for="feature in features" :key="feature.id">
          <div class="card mb-4 mt-4" style="height: 150px;">
            <div class="card-content">
              <div class="content">
                <div class="columns">
                  <div class="column is-2 p-0 has-text-centered">
                    <b style="font-size:4rem;color:#dddddd;">{{feature.id}}</b>
                  </div>
                  <div class="column">
                    {{feature[$props.lang]}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container is-widescreen p-4 has-background-grey-lighter mb-5">
      <b-image
            :src="require('@/assets/services/slides/auditoria_de_software.gif')"
            alt="slide"
        ></b-image>
    </div>
    <LangButtonComponent :lang="$props.lang" :alternatives="alternatives" />
  </main>
</template>

<script>
  import axios from "axios";
  import BannerComponent from "@/components/Banner/BannerComponent.vue";
  import LangButtonComponent from "@/../../edwinariza.ui/components/Buttons/LangButtonComponent.vue";
  export default {
  name: 'FormulatorView',
  components:{
    LangButtonComponent,
    BannerComponent
  },
  props: {
    title: {
      type: String,
      default: 'Auditor de Software.'
    },
    description: {
      type: String,
      default: 'Establece de manera acertada planes para desarrollar proyectos de tecnología, salud y agro.'
    },
    lang: {
      type: String,
      default: 'EN'
    }
  },
  data(){
    return {
      banner: {
        ES: {
          title: 'Auditoría de Software.',
          subtitle: 'Auditoría de software para identificar errores, vulnerabilidades, problemas de rendimiento y oportunidades de mejora.',
          note: 'Conoce los pasos para formular un proyecto exitoso. Solicita cotizaciones, resuelve tus dudas y conoce los proyectos presentados.'
        },
        EN: {
          title: 'Software Audit.',
          subtitle: 'Identify errors, vulnerabilities, performance issues, and opportunities for improvement.\n',
          note: 'Know the steps to formulate a successful project. Request quotes, solve your doubts, and learn about the projects presented.'
        }
      },
      features: [
        {
          id: 1,
          ES: "Evaluación de conceptos de seguridad de la información aplicando de conceptos de ISO 27001:2022.",
          EN: "Evaluation of information security concepts applying ISO 27001:2022 standards."
        },
        {
          id: 2,
          "ES": "Evaluación de arquitectura de software enfocada en la extensibilidad y sostenimiento en el mediano plazo.",
          "EN": "Evaluation of software architecture focused on extensibility and medium-term maintainability."
        },
        {
          id: 3,
          "ES": "Evaluación de la documentación asociada para usuarios y para registro ante entidades de control.",
          "EN": "Evaluation of documentation associated with users and for submission to regulatory authorities."
        },
        {
          id: 4,
          "ES": "Evaluación de la no dependencia del proveedor para proteger la continuidad de negocio.",
          "EN": "Evaluation of vendor independence to ensure business continuity."
        },
        {
          id: 5,
          "ES": "Evaluación de autenticación y roles.",
          "EN": "Evaluation of authentication and user roles."
        },
        {
          id: 6,
          "ES": "Evaluación del cumplimiento de las políticas de protección de datos acorde a la normatividad Colombiana.",
          "EN": "Evaluation of compliance with data protection policies in accordance with Colombian regulations."
        },
        {
          id: 7,
          "ES": "Evaluación de buenas practicas en la elaboración del código fuente.",
          "EN": "Evaluation of best practices in source code development."
        }
      ],
      alternatives: [
        {id: 'ES', name: 'Español', link: '/es/auditoria_de_software'},
        {id: 'EN', name: 'English', link: '/software_audit'}
      ]
    }
  },
  methods: {
    getProjects: function(){
      axios.get('https://edwinariza.com/api/projects/get_projects').then(response => {
        this.projects = response.data;
        this.isLoading = false;
      });
    },
  },
  beforeMount() {
    // CARGAMOS LOS ARTICULOS
    this.getProjects()
    this.$emit('updateMeta', this.title, this.description, this.lang);
  }
}
</script>

<style scoped>
  @media (min-width: 1024px) {
    header {
      line-height: 1.5;
    }
    header {
      display: flex;
      place-items: center;
      padding-right: calc(var(--section-gap) / 2);
    }
    header .wrapper {
      display: flex;
      place-items: flex-start;
      flex-wrap: wrap;
    }

  }
</style>