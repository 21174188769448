import { render, staticRenderFns } from "./LangButtonComponent.vue?vue&type=template&id=6a3f10fb&scoped=true"
import script from "./LangButtonComponent.vue?vue&type=script&lang=js"
export * from "./LangButtonComponent.vue?vue&type=script&lang=js"
import style0 from "./LangButtonComponent.vue?vue&type=style&index=0&id=6a3f10fb&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../edwinariza.web/node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a3f10fb",
  null
  
)

export default component.exports