<template>
  <main id="app">
    <NavbarComponent :lang="lang" message="IT Expert." @open-modal-contact="openModalContact" :menus="menus" />
    <section >
      <router-view  @open-modal-contact="openModalContact" @updateMeta="updateMetaInfo"></router-view>
    </section>
    <FooterComponent :lang="lang" />
    <WhatsAppButton :lang="lang" phoneNumber="+573186195270" company-name="Edwin Ariza" company-logo="https://edwinariza.com/static/favicon.png" text="Hey Edwin, Could you please help me with ..." />
    <ModalContactComponent :lang="lang"  :visible="isModalContact" @close="closeModalContact"/>
  </main>
</template>

<script>
import NavbarComponent from "../../edwinariza.ui/components/NavBar/NavBarComponent.vue";
import WhatsAppButton from "@/../../jumichica.vue.widget.whatsapp/src/components/Widget.vue";
import FooterComponent from "../../edwinariza.ui/components/Footer/FooterComponent.vue";
import ModalContactComponent from "@/components/Modals/ModalContactComponent.vue";
export default {
  name: 'App',
  metaInfo() {
    return {
      title: this.title,
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: this.description }
      ],
      htmlAttrs: {
        lang: this.lang // Aquí se establece el idioma de la ruta
      }
    };
  },
  data(){
    return {
      title: 'Edwin Ariza - IT Expert',
      description: 'IT Expert',
      lang: 'EN',
      isModalContact:false,
      loading:true,
      menus: [
        { id:1, name: { ES: 'Servicios', EN:'Services' }, url:'', submenus: [
            {id: 1, name: { ES:'Coordinación de proyecto', EN:'Project Management' }, url: '/project_manager'},
            {id: 2, name: { ES: 'Formulación de Proyecto', EN:'Project Formulation'}, url: '/formulator'},
            {id: 3, name: { ES: 'Proyectos de Investigación', EN:'Research Project'}, url: '/researcher'}
        ]},
        { id:2, name: { ES:'Herramientas', EN:'Toolkit' }, url:'', submenus: [
            {id: 1, name: { ES: 'Timebox', EN:'Timebox' }, url: '/toolkit/timebox'}
        ]},
        { id: 3, name: { ES: 'Blog', EN: 'Blog' }, url: '/blog' }
      ]
    }
  },
  components: {
    ModalContactComponent,
    FooterComponent,
    WhatsAppButton,
    NavbarComponent
  },
  methods:{
    openModalContact(){
      this.isModalContact=true;
    },
    closeModalContact(){
      this.isModalContact=false;
    },
    updateMetaInfo(title, description, lang){
      this.title = title;
      this.description = description;
      this.lang = lang;
    },
  }
}
</script>
<style>
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');
  html{
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
  }
  *{
    font-family: 'Poppins', sans-serif;
  }
</style>
